import {Auth} from "aws-amplify"
import Axios from "axios"

const apiUrl = process.env.REACT_APP_API_URL

const jwt = () =>
  Auth
    .currentSession()
    .then((s) => s.getIdToken())
    .then((idToken) => idToken.getJwtToken())

const apiKey = () =>
  Auth
    .currentUserInfo()
    .then(userInfo => userInfo.attributes)
    .then(attributes => attributes["sub"])

export const api = async () => Axios.create({
  headers: {
    "Authorization": `Bearer ${await jwt()}`,
    "X-Api-Key": `${await apiKey()}`,
  },
  baseURL: apiUrl
})
