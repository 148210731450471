import {StyledAlert} from "../../Styled/Styled";
import {AlertTitle} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import {threatLevelAssessmentState} from "../../../state/threatLevelAssessment";
import {RiskFactorName} from "../RiskFactorValue/RiskFactorValue";
import {useRecoilValue} from "recoil";
import {threatLevelAssessmentRequestState} from "../../../state/threatLevelAssessmentRequest";

export const RiskMitigationInformation = () => {
  const {t} = useTranslation()
  const request = useRecoilValue(threatLevelAssessmentRequestState)
  const result = useRecoilValue(threatLevelAssessmentState)

  if (!request || !result) {
    return null
  }

  if (result.riskMitigationFactors.length === 0) {
    return (
      <StyledAlert severity="warning">
        <AlertTitle>{t('Nie ma możliwości obniżenia poziomu ryzyka aparatu poprzez zmianę przyjętych danych obliczeniowych')}.</AlertTitle>
      </StyledAlert>
    )
  }

  const riskLevel = t(`aparat.${request.instrumentSymbol}.poziomZagrożenia.${result.threatLevel}.nazwa`)
  const riskLevelMitigated = t(`aparat.${request.instrumentSymbol}.poziomZagrożenia.${result.threatLevelMitigated}.nazwa`)

  return (
    <StyledAlert severity="warning">
      Przyjęte warunki pracy aparatu prowadzą do oszacowania jego ryzyka na
      poziomie <strong>{riskLevel}</strong>.<br/>
      Istnieje możliwość obniżenia ryzyka do poziomu <strong>{riskLevelMitigated}</strong> poprzez zmianę przyjętych
      danych obliczeniowych dla następujących czynników
      (<strong>{request.instrumentMode === "DESIGN_PHASE" ? "konstrukcja aparatu i " : ""}warunki pracy aparatu</strong>):
      <ul>
        {result.riskMitigationFactors.map(riskMitigationFactor => (
          <li key={riskMitigationFactor}>
            <strong><RiskFactorName instrument={request.instrumentSymbol}
                                    riskFactor={riskMitigationFactor}/></strong>:&nbsp;
            {t(`czynnikRyzyka.${riskMitigationFactor}.mitygacjaRyzyka`)}
          </li>
        ))}
      </ul>
    </StyledAlert>
  )
}
