import {Dictionary} from "lodash"
import {atom} from "recoil"
import {FormDefinition} from "./threatLevelAssessmentFormDefinition"

export interface FormField {
  value: string
  error: boolean
}

export interface Form {
  definition: FormDefinition
  fields: Dictionary<FormField>
}

export const threatLevelAssessmentFormState = atom<Form>({
  key: 'threatLevelAssessmentFormState',
  default: {
    definition: {},
    fields: {}
  }
})
