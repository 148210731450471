import {createStyles, Theme} from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Toolbar from "@material-ui/core/Toolbar"
import React from "react"
import {UserAvatar} from "./UserAvatar/UserAvatar"
import {useTranslation} from "react-i18next"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      justifyContent: "space-between"
    },
    appTitle: {
      fontWeight: "bold",
      fontVariant: "small-caps",
      display: "none",
      '@media (min-width: 320px)': {
        display: "block"
      },
      '@media (min-width: 507px)': {
        textDecoration: "underline"
      },
      '@media (min-width: 610px)': {
        fontSize: "20px",
      },
      '@media (min-width: 810px)': {
        fontSize: "28px"
      }
    },
    appSubtitle: {
      fontWeight: "bold",
      fontVariant: "small-caps",
      display: "none",
      '@media (min-width: 515px)': {
        display: "block"
      }
    },
    avatar: {
      width: "164px",
      textAlign: "right"
    }
  })
)

export const TopBar = () => {
  const [t] = useTranslation()
  const classes = useStyles()

  return (
    <AppBar position="sticky">
      <Toolbar className={classes.toolbar}>
        <a href="https://www.grupa-wolff.eu/" style={{display: "inline-block", lineHeight: 0}}>
          <img src="/logo-grupa-wolff-r2.png" alt="logo"/>
        </a>
        <div style={{textAlign: "center"}}>
          <div className={classes.appTitle}>{t("aplikacja.tytuł")}</div>
          <div className={classes.appSubtitle}>{t("aplikacja.podtytuł")}</div>
        </div>
        <div className={classes.avatar}>
          <UserAvatar/>
        </div>
      </Toolbar>
    </AppBar>
  )
}
