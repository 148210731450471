import {selector} from "recoil"
import {isValid} from "./threatLevelAssessmentFormDefinition"
import {threatLevelAssessmentFormState} from "./threatLevelAssessmentForm"

export const threatLevelAssessmentFormValidState = selector({
  key: 'threatLevelAssessmentFormValidState',
  get: ({get}) => {
    const form = get(threatLevelAssessmentFormState)
    const formDefinition = Object.entries(form.definition)
    if (formDefinition.length === 0) {
      return false
    }

    return formDefinition.every(entry => isValid(entry[1], form.fields[entry[0]]?.value))
  }
})
