import React, {useCallback, useMemo} from "react"
import {useRecoilValue, useSetRecoilState} from "recoil"
import {FormFieldDefinition, isValid} from "../../../state/threatLevelAssessmentFormDefinition"
import {FormField, threatLevelAssessmentFormState} from "../../../state/threatLevelAssessmentForm"
import {useTranslation} from "react-i18next"
import {StyledSelect} from "../../Form/StyledSelect/StyledSelect"
import {MenuItem} from "@material-ui/core"
import {StyledTextField} from "../../Form/StyledTextField/StyledTextField"
import {InfoDecorator} from "../../InfoDecorator/InfoDecorator"

interface Props {
  id: string,
  field: FormField
}

export const RiskAssessmentFormField = ({id, field}: Props) => {
  const [t] = useTranslation()
  const setThreatLevelAssessmentForm = useSetRecoilState(threatLevelAssessmentFormState)
  const form = useRecoilValue(threatLevelAssessmentFormState)
  const definition = useMemo(
    () => form.definition[id],
    [form.definition, id]
  )

  const setValue = useCallback(
    (definition: FormFieldDefinition, value: string) => {
      setThreatLevelAssessmentForm(prev => {
        if (definition.type === 'number') {
          if (!new RegExp(/^(\d*[.,])?\d*$/).test(value)) {
            return prev
          }

          value = value
            .replace(new RegExp(/,/g), '.')
            .replace(new RegExp(/^\./g), '0.')
            .replace(new RegExp(/^0+/g), '0')
            .replace(new RegExp(/^0(\d)/g), '$1')
        }

        return {
          ...prev,
          fields: {
            ...prev.fields,
            [id]: {
              ...prev.fields[id],
              value,
              error: !isValid(definition, value)
            }
          }
        }
      })
    },
    [id, setThreatLevelAssessmentForm]
  )

  const props = useMemo(
    () => ({
      label: t(definition.name),
      value: field.value,
      error: field.error,
      adornment: definition.type === 'number' ? definition.unit : undefined,
      onChange: (value: string) => setValue(definition, value)
    }),
    [t, definition, field, setValue]
  )

  return useMemo(
    () => (
      <InfoDecorator key={id} hintKeys={definition.hintKeys}>
        {definition.type === 'select'
          ? <StyledSelect {...props}>
            {definition.options.map(o => <MenuItem key={o.value} value={o.value}>{t(o.name)}</MenuItem>)}
          </StyledSelect>
          :
          <StyledTextField {...props} type={definition.type}/>
        }
      </InfoDecorator>
    ),
    [t, id, definition, props]
  )
}
