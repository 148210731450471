export const translations = {
  pl: {
    "Sign in to your account": "Zaloguj się do swojego konta",
    "Sign In": "Zaloguj się",
    "Create Account": "Utwórz konto",
    "Email Address *": "Adres email *",
    "Enter your email address": "Wprowadź swój adres email",
    "Password *": "Hasło *",
    "Enter your password": "Wpisz swoje hasło",
    "Enter your Password": "Wpisz swoje nowe hasło",
    "Enter your email": "Wpisz swój adres email",
    "Code *": "Kod",
    "Please confirm your Password": "Potwierdź wprowadzone hasło",
    "Forgot your password?": "Zapomniałeś hasło?",
    "Reset Password": "Ustaw nowe hasło",
    "Sign in": "Zaloguj się",
    "User does not exist.": "Użytkownik nie istnieje.",
    "Custom auth lambda trigger is not configured for the user pool.": "Błędne hasło.",
    "Incorrect username or password.": "Nieprawidłowy adres email bądź hasło.",
    "User is disabled.": "Twoje konto wymaga zatwierdzenia.",
    "User already exists": "Użytkownik już istnieje.",

    "Reset your password": "Ustaw nowe hasło",
    "Send Code": "Wyślij kod",
    "Back to Sign In": "Powrót do logowania",
    "Username/client id combination not found.": "Nieprawidłowy adres email.",
    "Username cannot be empty": "Adres email nie może być pusty.",

    "Verification code": "Kod potwierdzający",
    "Enter code": "Wprowadź kod",
    "New password": "Nowe hasło",
    "New Password": "Nowe Hasło",
    "Enter your new password": "Wprowadź swoje nowe hasło",
    "Submit": "Zatwierdź",
    "Confirmation code cannot be empty": "Kod potwierdzający nie może być pusty.",
    "Password cannot be empty": "Hasło nie może być puste.",
    "Invalid verification code provided, please try again.": "Podano błędny kod potwierdzający, spróbuj ponownie.",

    "Change Password": "Zmień hasło",
    "Change": "Zmień",

    "Password did not conform with policy: Password not long enough": "Hasło musi składać się z co najmniej 8 znaków oraz musi zawierać małą literę, dużą literę, cyfrę oraz znak specjalny.",
    "Password did not conform with policy: Password must have lowercase characters": "Hasło musi składać się z co najmniej 8 znaków oraz musi zawierać małą literę, dużą literę, cyfrę oraz znak specjalny.",
    "Password did not conform with policy: Password must have uppercase characters": "Hasło musi składać się z co najmniej 8 znaków oraz musi zawierać małą literę, dużą literę, cyfrę oraz znak specjalny.",
    "Password did not conform with policy: Password must have symbol characters": "Hasło musi składać się z co najmniej 8 znaków oraz musi zawierać małą literę, dużą literę, cyfrę oraz znak specjalny.",
    "Password did not conform with policy: Password must have numeric characters": "Hasło musi składać się z co najmniej 8 znaków oraz musi zawierać małą literę, dużą literę, cyfrę oraz znak specjalny.",
    "Your passwords must match": "Hasła muszą być takie same.",
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$": "Hasło musi składać się z co najmniej 8 znaków oraz musi zawierać małą literę, dużą literę, cyfrę oraz znak specjalny.",
  },
}
