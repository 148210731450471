import styled from "@material-ui/core/styles/styled";
import Paper from "@material-ui/core/Paper";
import {Alert} from "@material-ui/lab";

const margins = {
  marginTop: "0.25em",
  marginBottom: "0.25em",
}

export const StyledPaper = styled(Paper)({
  ...margins,
  padding: "0.5em",
})

export const StyledAlert = styled(Alert)({
  ...margins,
  textAlign: "justify",
  transition: "background-color 500ms ease-in-out",
})
