import {useRecoilSnapshot} from "recoil"
import {useEffect} from "react"

export const RecoilAtomStateChangeLogger = () => {
  const snapshot = useRecoilSnapshot()

  useEffect(() => {
    console.debug('The following atoms were modified:')
    // @ts-ignore
    for (const node of snapshot.getNodes_UNSTABLE({isModified: true})) {
      console.debug(node.key, snapshot.getLoadable(node))
    }
  }, [snapshot])

  return null
}
