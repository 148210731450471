import React from 'react'
import ReactDOM from 'react-dom'
import {App} from './App'
import './i18n'
import reportWebVitals from './reportWebVitals'
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing"

const environment = process.env.REACT_APP_ENVIRONMENT ?? 'local'
console.log("Environment: ", environment)

Sentry.init({
  dsn: "https://393306dc62e3402fb4981eb08af474dc@o523215.ingest.sentry.io/5635342",
  environment,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
)

// TODO: What is it?

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
