import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import React, {useCallback, useEffect, useRef, useState} from "react"
import {Trans, useTranslation} from "react-i18next"
import {useHistory} from "react-router-dom"
import {useRecoilValue} from "recoil"
import {threatLevelAssessmentState} from "../../state/threatLevelAssessment"
import {GridRow} from "./GridRow/GridRow"
import {instrumentRiskFactorsState} from "../../state/instrumentRiskFactors"
import {RiskFactorValue} from "./RiskFactorValue/RiskFactorValue"
import {threatLevelAssessmentRequestState} from "../../state/threatLevelAssessmentRequest"
import {instrumentState} from "../../state/instrument"
import {AlertTitle} from "@material-ui/lab"
import {ProcessSafetyDefinition} from "../RiskAssessmentForm/ProcessSafetyDefinition/ProcessSafetyDefinition"
import {StyledAlert, StyledPaper} from "../Styled/Styled";
import {RiskMitigationInformation} from "./RiskMitigationInformation/RiskMitigationInformation";

export const RiskAssessmentResult = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const instrument = useRecoilValue(instrumentState)
  const instrumentRiskFactors = useRecoilValue(instrumentRiskFactorsState)
  const threatLevelAssessmentRequest = useRecoilValue(threatLevelAssessmentRequestState)
  const threatLevelAssessment = useRecoilValue(threatLevelAssessmentState)
  const [backgroundColor, setBackgroundColor] = useState<string>()
  const instrumentTypesInfo = useRef<HTMLElement>()

  useEffect(
    () => window.scroll(0, 0),
    []
  )

  const highlightInstrumentTypes = useCallback(e => {
      e.preventDefault()
      setBackgroundColor("rgb(253, 180, 180)")
      setTimeout(() => setBackgroundColor(undefined), 1000)
    },
    []
  )

  if (!threatLevelAssessment || !instrument) {
    history.push("/")
    return null
  }

  const threatLevel = threatLevelAssessment.threatLevel
  return (
    <Box display="flex" flexDirection="column">
      <StyledAlert severity="error">
        <AlertTitle>{t('Oszacowany poziom ryzyka')}: <strong>{t(`aparat.${instrument?.symbol}.poziomZagrożenia.${threatLevel}.nazwa`)}</strong>{threatLevelAssessment.aggregatedRisk &&
            <span> ({t('Ryzyko sumaryczne')} = {threatLevelAssessment.aggregatedRisk})</span>}</AlertTitle>
      </StyledAlert>
      <StyledPaper>
        <Grid container spacing={1}>
          <GridRow name={t('Tryb')} value={t(`tryb.${threatLevelAssessmentRequest.instrumentMode}.nazwa`)}/>
          <GridRow name={t('Substancja')} value={threatLevelAssessmentRequest.substance}/>
          <GridRow name={t('Aparat')} value={t(`aparat.${threatLevelAssessmentRequest.instrumentSymbol}.nazwa`)}/>
          <GridRow name={t('Typ aparatu')}
                   value={
                     // eslint-disable-next-line jsx-a11y/anchor-is-valid
                     <a href="#" onClick={highlightInstrumentTypes}>{instrument?.type}</a>
                   }/>
        </Grid>
        <Grid container spacing={1}>
          {instrumentRiskFactors.map(riskFactor =>
            <>
              <RiskFactorValue key={riskFactor.symbol} riskFactor={riskFactor}/>
              {riskFactor.symbol === "DGW"
                ? <Grid item xs={12} style={{textAlign: "center", marginTop: "0.3em", textDecoration: "underline"}}>
                  <strong>{t('Warunki pracy aparatu')}</strong>
                </Grid>
                : null}
            </>
          )}
        </Grid>
      </StyledPaper>
      <StyledAlert severity="info" style={{backgroundColor: backgroundColor}} ref={instrumentTypesInfo}>
        <AlertTitle>{t('Typy aparatów')}</AlertTitle>
        <Trans>typyAparatów.informacja</Trans>
      </StyledAlert>
      <StyledAlert severity="warning">
        <Trans>aparat.{instrument?.symbol}.poziomZagrożenia.{threatLevel}.opis</Trans><br/>
        <ProcessSafetyDefinition/>
      </StyledAlert>
      <RiskMitigationInformation/>
      <Button onClick={() => history.goBack()} color="primary" variant="contained">{t('Wstecz')}</Button>
    </Box>
  )
}
