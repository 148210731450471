import {GridRow} from "../GridRow/GridRow"
import React from "react"
import {RiskFactor} from "../../../state/riskFactors"
import {useTranslation} from "react-i18next"
import {useRecoilValue} from "recoil"
import {instrumentState} from "../../../state/instrument"
import {threatLevelAssessmentFormState} from "../../../state/threatLevelAssessmentForm"

interface RiskFactorNameProps {
  instrument: string,
  riskFactor: string,
}

export const RiskFactorName = ({instrument, riskFactor}: RiskFactorNameProps) => {
  const {t} = useTranslation()

  return <>
    {t([
      `aparat.${instrument}.czynnikRyzyka.${riskFactor}.nazwa`,
      `czynnikRyzyka.${riskFactor}.nazwa`
    ])}
  </>
}

interface RiskFactorValueProps {
  riskFactor: RiskFactor,
}

export const RiskFactorValue = ({riskFactor}: RiskFactorValueProps) => {
  const instrument = useRecoilValue(instrumentState)!
  const form = useRecoilValue(threatLevelAssessmentFormState)

  return <GridRow key={riskFactor.symbol}
                  name={<RiskFactorName instrument={instrument.symbol} riskFactor={riskFactor.symbol}/>}
                  value={
                    <>
                      {form.fields[riskFactor.symbol].value}
                      &nbsp;
                      <span style={{color: "gray"}}>
                             {riskFactor.type === "numerical" && riskFactor.unit ? riskFactor.unit : ""}
                           </span>
                    </>
                  }/>
}
