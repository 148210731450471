import {selector, waitForAll} from "recoil"
import {instrumentState} from "./instrument"
import {RiskFactor, riskFactorsState} from "./riskFactors"

export const instrumentRiskFactorsState = selector({
  key: 'instrumentRiskFactorsState',
  get: ({get}): RiskFactor[] => {
    const [riskFactors, instrument] = get(waitForAll([riskFactorsState, instrumentState]))

    if (!instrument) {
      return []
    }

    return instrument
      .riskFactors
      .map(instrumentRiskFactor => {
          const riskFactor = riskFactors.find(f => f.symbol === instrumentRiskFactor.symbol)
          if (!riskFactor) {
            throw new Error(`Risk factor = '${instrumentRiskFactor.symbol}' not found!`)
          }

          if (riskFactor.type === "alternative") {
            return {
              ...riskFactor,
              options: instrumentRiskFactor.options ?? riskFactor.options
            }
          }

          return riskFactor
        }
      )
  }
})
