import {Dictionary} from "lodash"
import {selector, waitForAll} from "recoil"
import {instrumentsState} from "./instruments"
import {instrumentRiskFactorsState} from "./instrumentRiskFactors"
import {instrumentSymbolState} from "./instrumentSymbol"

interface SelectOption {
  value: string
  name: string
}

interface IFormFieldDefinition {
  name: string | string[]
  hintKeys: string | string[]
  preserve: boolean
}

interface TextFormFieldDefinition extends IFormFieldDefinition {
  type: 'text'
}

interface NumberTextFormFieldDefinition extends IFormFieldDefinition {
  type: 'number'
  unit?: string
}

interface SelectFormFieldDefinition extends IFormFieldDefinition {
  type: 'select'
  options: SelectOption[]
}

export type FormFieldDefinition = TextFormFieldDefinition | NumberTextFormFieldDefinition | SelectFormFieldDefinition

export type FormDefinition = Dictionary<FormFieldDefinition>

export const threatLevelAssessmentFormDefinitionState = selector<FormDefinition>({
  key: 'threatLevelAssessmentFormDefinitionState',
  get: ({get}) => {
    const [
      instruments,
      riskFactors,
      instrument,
    ] = get(waitForAll([
      instrumentsState,
      instrumentRiskFactorsState,
      instrumentSymbolState,
    ]))

    const riskFactorsFormDefinitions: FormDefinition = {}
    riskFactors
      .forEach(riskFactor => {
          riskFactorsFormDefinitions[riskFactor.symbol] = {
            name: [
              `aparat.${instrument}.czynnikRyzyka.${riskFactor.symbol}.nazwa`,
              `czynnikRyzyka.${riskFactor.symbol}.nazwa`
            ],
            type: riskFactor.type === 'numerical' ? 'number' : 'select',
            hintKeys: [
              `aparat.${instrument}.czynnikRyzyka.${riskFactor.symbol}.podpowiedź`,
              `czynnikRyzyka.${riskFactor.symbol}.podpowiedź`
            ],
            preserve: false,
            unit: riskFactor.type === 'numerical' ? riskFactor?.unit : undefined,
            options: riskFactor.type === 'alternative'
              ? riskFactor.options.map(o => ({
                value: o,
                name: `czynnikRyzyka.${riskFactor.symbol}.opcja.${o}`
              }))
              : []
          }
        }
      )

    return {
      mode: {
        name: 'Tryb',
        type: 'select',
        hintKeys: 'formularz.Tryb.podpowiedź',
        preserve: true,
        options: [
          {
            value: 'DESIGN_PHASE',
            name: 'tryb.DESIGN_PHASE.nazwa'
          },
          {
            value: 'EXISTING_SYSTEM',
            name: 'tryb.EXISTING_SYSTEM.nazwa'
          }
        ]
      },
      substance: {
        name: 'Substancja',
        type: 'text',
        hintKeys: 'formularz.Substancja.podpowiedź',
        preserve: true,
      },
      instrument: {
        name: 'Aparat',
        type: 'select',
        hintKeys: 'formularz.Aparat.podpowiedź',
        preserve: true,
        options: instruments.map(i => (
          {
            value: i.symbol,
            name: `aparat.${i.symbol}.nazwa`
          }
        ))
      },
      ...riskFactorsFormDefinitions
    }
  }
})

export const isValid = (definition: FormFieldDefinition, value: string) => {
  switch (definition.type) {
    case "text":
    case "select":
      return new RegExp(/\S/).test(value)
    case "number":
      return new RegExp(/^(\d+\.)?\d+$/).test(value)
  }
}
