import i18n from "i18next"
import Backend from "i18next-http-backend"
import {initReactI18next} from "react-i18next"
import {configUrl} from "./common/config"

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    // FIXME: turn off debug
    debug: true,
    lng: 'pl',
    fallbackLng: 'pl',
    ns: ['tlumaczenia'],
    defaultNS: 'tlumaczenia',
    backend: {
      loadPath: `${configUrl}/lokalizacje/{{lng}}/{{ns}}.json`
    }
  }).catch(e => console.log(e))

export default i18n
