import {createStyles, Theme} from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React from "react"

interface Props {
  children: React.ReactNode
  label: any
  placeholder?: string
  value: string
  error: boolean
  onChange: (value: string) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
)

export const StyledSelect = ({children, label, placeholder, value, error, onChange}: Props) => {
  const classes = useStyles()
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select label={label}
              placeholder={placeholder}
              value={value}
              error={error}
              onChange={e => onChange(e.target.value as string)}
              onBlur={() => onChange(value)}>
        {children}
      </Select>
    </FormControl>
  )
}
