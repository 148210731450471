import {
  Authenticator as AmplifyAuthenticator,
  CheckboxField,
  translations,
  useAuthenticator
} from "@aws-amplify/ui-react"
import {Amplify, Auth, I18n} from "aws-amplify"
import React, {ReactNode, useCallback, useState} from "react"
import {translations as customTranslations} from "./translations"
import '@aws-amplify/ui-react/styles.css'
import {AccountConfirmationRequired} from "./AccountConfirmationRequired/AccountConfirmationRequired";
import {Link} from "@material-ui/core";

const userPoolId = process.env.REACT_APP_USER_POOL_ID
const webClientId = process.env.REACT_APP_WEB_CLIENT_ID

Amplify.configure({
  aws_project_region: "eu-west-1",
  aws_cognito_region: "eu-west-1",
  aws_user_pools_id: userPoolId,
  aws_user_pools_web_client_id: webClientId,
  oauth: {}
})

I18n.setLanguage('pl')
I18n.putVocabularies(translations)
I18n.putVocabularies(customTranslations)

interface AuthenticatorProps {
  children: ReactNode
}

export const Authenticator = ({children}: AuthenticatorProps) => {
  const [confirmed, setConfirmed] = useState(false)

  const handleConfirmSignUp = useCallback(
    async function ({username, code}: { username: string, code: string }): Promise<any> {
      try {
        await Auth.confirmSignUp(username, code)
        console.log('Code confirmed successfully.')
        setConfirmed(true)
      } catch (error) {
        console.error('Error during sign up confirmation', error)
        throw error;
      }
    },
    [setConfirmed]
  )

  const validateCustomSignUp = useCallback(
    async function (formData: any): Promise<any> {
      const errors: Record<string, string> = {}
      if (!formData.acknowledgement) {
        errors.acknowledgement = 'Musisz wyrazić zgodę na warunki użytkowania i politykę prywatności.'
      }
      if (!formData.marketingConsent) {
        errors.marketingConsent = 'Musisz wyrazić zgodę na otrzymywanie informacji marketingowych.'
      }

      return errors
    },
    []
  )

  if (confirmed) {
    return <AccountConfirmationRequired/>
  }

  const formFields = {
    signUp: {
      name: {
        order: 1,
        isRequired: true,
        label: 'Imię i nazwisko',
        placeholder: 'Wpisz swoje imię i nazwisko'
      },
      email: {
        order: 2,
        isRequired: true,
        label: 'Adres e-mail',
      },
      'custom:company': {
        order: 3,
        isRequired: true,
        label: 'Firma',
        placeholder: 'Wpisz nazwę firmy'
      },
    },
  }

  return <AmplifyAuthenticator
    services={{handleConfirmSignUp, validateCustomSignUp}}
    loginMechanisms={['email']}
    formFields={formFields}
    components={{
      SignUp: {
        FormFields() {
          const {validationErrors} = useAuthenticator();

          return (
            <>
              {/* Re-use default `Authenticator.SignUp.FormFields` */}
              <AmplifyAuthenticator.SignUp.FormFields/>

              Hasło musi składać się z co najmniej 8 znaków oraz musi zawierać małą literę, dużą literę, cyfrę oraz znak specjalny.

              <Link href="https://www.grupa-wolff.eu/aw-oz-warunki-korzystania-z-aplikacji"
                    underline="always"
                    target="_blank">Ogólne warunki użytkowania</Link>
              <Link href="https://www.grupa-wolff.eu/rodo-klauzula-informacyjna-polityka-prywatnosc-pliki-cookies/"
                    underline="always"
                    target="_blank">Polityka prywatności</Link>

              {/* Append & require Terms & Conditions field to sign up  */}
              <CheckboxField
                errorMessage={validationErrors.acknowledgement as string}
                hasError={!!validationErrors.acknowledgement}
                name="acknowledgement"
                value="yes"
                label="Akceptuję ogólne warunki użytkowania i politykę prywatności"
              />
              <CheckboxField
                errorMessage={validationErrors.marketingConsent as string}
                hasError={!!validationErrors.marketingConsent}
                name="marketingConsent"
                value="yes"
                label="Wyrażam zgodę na otrzymywanie informacji marketingowych drogą elektroniczną od Grupa Wolff sp. z o.o. sp.k."
              />
            </>
          );
        },
      },
    }}
  >
    {() => children}
  </AmplifyAuthenticator>
}
