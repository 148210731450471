import {selector} from "recoil"
import {config} from "../common/config"

interface RiskFactorDto {
  symbol: string
  typ: "numeryczny" | "logiczny"
  jednostka?: string
  opcje?: string[]
}

const toRiskFactor = (dto: RiskFactorDto): RiskFactor => {
  switch (dto.typ) {
    case "numeryczny":
      return {
        symbol: dto.symbol,
        type: "numerical",
        unit: dto.jednostka
      }
    case "logiczny":
      return {
        symbol: dto.symbol,
        type: "alternative",
        options: dto.opcje!
      }
  }
}

interface RiskFactorBase {
  symbol: string
}

export interface NumericalRiskFactor extends RiskFactorBase {
  type: "numerical",
  unit?: string
}

export interface AlternativeRiskFactor extends RiskFactorBase {
  type: "alternative",
  options: string[]
}

export type RiskFactor = NumericalRiskFactor | AlternativeRiskFactor

export type RiskFactors = RiskFactor[]

export const riskFactorsState = selector({
  key: 'riskFactorsState',
  get: () =>
    config
      .get('/konfiguracja/czynniki-ryzyka.json')
      .then(res => res.data as RiskFactorDto[])
      .then(dtos => dtos.map(toRiskFactor))
})
