import {createStyles, Theme} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React from "react"

interface GridRowProps {
  name: any
  value: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      textAlign: "right",
      fontWeight: "bold"
    }
  })
)

export const GridRow = ({name, value}: GridRowProps) => {
  const classes = useStyles()

  return <>
    <Grid item xs={6} className={classes.name}>
      {name}
    </Grid>
    <Grid item xs={6}>
      {value}
    </Grid>
  </>
}
