import {selector, waitForAll} from "recoil"
import {Instrument, instrumentsState} from "./instruments"
import {instrumentSymbolState} from "./instrumentSymbol"

export const instrumentState = selector<Instrument | undefined>({
  key: 'instrumentState',
  get: ({get}) => {
    const [instruments, instrumentSymbol] = get(waitForAll([instrumentsState, instrumentSymbolState]))
    return instruments.find(i => i.symbol === instrumentSymbol)
  }
})
