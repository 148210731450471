import {Dictionary} from "lodash"
import {selector} from "recoil"
import {threatLevelAssessmentFormState} from "./threatLevelAssessmentForm"
import {instrumentRiskFactorsState} from "./instrumentRiskFactors"

interface ThreatLevelAssessmentRequest {
  substance: string,
  instrumentSymbol: string,
  instrumentMode: string,
  riskFactorValues: Dictionary<string>
}

export const threatLevelAssessmentRequestState = selector<ThreatLevelAssessmentRequest>({
  key: 'threatLevelAssessmentRequestState',
  get: ({get}) => {
    const form = get(threatLevelAssessmentFormState)
    const riskFactors = get(instrumentRiskFactorsState)

    const riskFactorValues: Dictionary<string> = {}
    riskFactors.forEach(f => {
      riskFactorValues[f.symbol] = form.fields[f.symbol].value
    })

    return {
      substance: form.fields.substance?.value ?? "",
      instrumentSymbol: form.fields.instrument?.value ?? "",
      instrumentMode: form.fields.mode?.value ?? "",
      riskFactorValues
    }
  }
})
