import {Container} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import React, {Component, ReactNode} from "react"
import {WithTranslation, withTranslation} from "react-i18next"

interface Props extends WithTranslation {
  children: ReactNode
}

interface State {
  hasError: boolean
  statusCode?: number
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(error: any): State {
    return {
      hasError: true,
      statusCode: error.response?.status
    }
  }

  public render() {
    if (!this.state.hasError) {
      return this.props.children
    }

    let errorMessage = 'Ups! Nie wszystko poszło zgodnie z planem...'
    if (this.state.statusCode === 429) {
      errorMessage = 'Przepraszamy! Wykorzystałeś przysługujący Ci limit w bieżącym okresie.'
    }

    return (
      <Container maxWidth="xs" style={{
        padding: "20px 0",
        textAlign: "center"
      }}>
        <img width="100%" src="/boom.png" alt="explosion"/>
        <Typography variant="h5">{this.props.t(errorMessage)}</Typography>
        <Button variant="contained"
                onClick={() => window.location.reload()}>{this.props.t('Spróbuj szczęścia jeszcze raz!')}</Button>
      </Container>
    )
  }

}

export default withTranslation()(ErrorBoundary)
