import Box from "@material-ui/core/Box"
import React, {useCallback} from "react"
import {Trans, useTranslation} from "react-i18next"
import {useHistory} from "react-router-dom"
import {useRecoilState, useRecoilValue} from "recoil"
import {threatLevelAssessmentFormState} from "../../state/threatLevelAssessmentForm"
import Button from "@material-ui/core/Button"
import {RiskAssessmentFormField} from "./RiskAssessmentFormField/RiskAssessmentFormField"
import {threatLevelAssessmentFormValidState} from "../../state/threatLevelAssessmentFormValid"
import {isValid} from "../../state/threatLevelAssessmentFormDefinition"
import _ from "lodash"
import {useResetThreatLevelAssessmentForm} from "../../hooks/useResetThreatLevelAssessmentForm"
import {Alert, AlertTitle} from "@material-ui/lab"

export const RiskAssessmentForm = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const [threatLevelAssessmentForm, setThreatLevelAssessmentForm] = useRecoilState(threatLevelAssessmentFormState)
  const formValid = useRecoilValue(threatLevelAssessmentFormValidState)
  const resetThreatLevelAssessmentForm = useResetThreatLevelAssessmentForm()

  const forceValidation = useCallback(
    () => {
      setThreatLevelAssessmentForm(prev => {
        const cloned = _.cloneDeep(prev)
        Object
          .entries(prev.fields)
          .forEach(entry => cloned.fields[entry[0]].error = !isValid(threatLevelAssessmentForm.definition[entry[0]], entry[1].value))
        return cloned
      })
    },
    [threatLevelAssessmentForm.definition, setThreatLevelAssessmentForm]
  )

  let bottom
  if (threatLevelAssessmentForm?.fields?.instrument?.value) {
    bottom = (
      <Box display="flex">
        <Button variant="contained"
                onClick={resetThreatLevelAssessmentForm}
                color="primary">
          {t('Wyczyść')}
        </Button>
        <Button type="submit"
                variant="contained"
                color={formValid ? "primary" : "secondary"}
                style={{flexGrow: 1, marginLeft: "10px"}}>
          {t('Oceń poziom ryzyka')}
        </Button>
      </Box>
    )
  } else {
    bottom = (
      <Alert severity="info" style={{textAlign: "justify"}}>
        <AlertTitle>{t('podstawoweZałożenia.tytuł')}</AlertTitle>
        <Trans components={{sub: <sub />}}>podstawoweZałożenia.opis</Trans>
      </Alert>
    )
  }

  return (
    <form onSubmit={event => {
      event.preventDefault()

      if (!formValid) {
        forceValidation()
        window.scroll({left: 0, top: 0, behavior: "smooth"})
        return
      }

      history.push("/threat-level-assessment/result")
    }}>
      <Box display="flex" flexDirection="column">
        {Object
          .entries(threatLevelAssessmentForm.fields)
          .map(([id, field]) => <RiskAssessmentFormField key={id} id={id} field={field}/>)
        }
        {bottom}
      </Box>
    </form>
  )
}
