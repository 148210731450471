import {createStyles, Theme} from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import makeStyles from "@material-ui/core/styles/makeStyles"
import TextField from "@material-ui/core/TextField"
import React from "react"

interface Props {
  label: any
  type: 'text' | 'number'
  value: string
  error: boolean
  onChange: (value: string) => void
  placeholder?: string
  adornment?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
)

export const StyledTextField = ({label, type, placeholder, adornment, value, error, onChange}: Props) => {
  const classes = useStyles()

  return (
    <TextField type="text"
               error={error}
               label={label}
               placeholder={placeholder}
               className={classes.textField}
               InputProps={{
                 endAdornment: adornment
                   ? <InputAdornment position="end">{adornment}</InputAdornment>
                   : undefined,
                 inputProps: {
                   inputMode: (type === 'number') ? 'tel' : 'text'
                 }
               }}
               variant="outlined"
               value={value}
               onChange={e => onChange(e.target.value)}
               onBlur={() => onChange(value)}
    />
  )
}
