import {useSetRecoilState} from "recoil"
import {threatLevelAssessmentFormState} from "../state/threatLevelAssessmentForm"
import _ from "lodash"

export const useResetThreatLevelAssessmentForm = () => {
  const setThreatLevelAssessmentForm = useSetRecoilState(threatLevelAssessmentFormState)

  return () => {
    setThreatLevelAssessmentForm(prev => (
      {
        definition: _.pickBy(prev.definition, (value, _) => value.preserve),
        fields: _(prev.fields)
          .pickBy((_, key) => prev.definition[key].preserve)
          .mapValues(() => ({
            value: '',
            error: false
          }))
          .value()
      })
    )
  }
}
