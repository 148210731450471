import Button from "@material-ui/core/Button"
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core"
import React, {useEffect, useRef, useState} from "react"
import {Trans, useTranslation} from "react-i18next"
import {useRecoilValue} from "recoil"
import {instrumentState} from "../../../state/instrument"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

export const ProcessSafetyDefinition = () => {
  const {t} = useTranslation()
  const instrument = useRecoilValue(instrumentState)
  const [open, setOpen] = useState(false)

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open])

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outlined"
              color="secondary">{t('Definicja bezpieczeństwa procesowego')}</Button>
      <Dialog open={open}
              onClose={() => setOpen(false)}
              scroll="paper"
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{t('Definicja bezpieczeństwa procesowego')}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description"
                             ref={descriptionElementRef}
                             tabIndex={-1}
                             align="justify">
            <Trans>aparat.{instrument?.symbol}.definicjaBezpieczeństwaProcesowego</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon/>
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
