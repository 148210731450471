import {useRecoilValue, useSetRecoilState} from "recoil"
import {threatLevelAssessmentFormDefinitionState} from "../../state/threatLevelAssessmentFormDefinition"
import {Form, threatLevelAssessmentFormState} from "../../state/threatLevelAssessmentForm"
import {ReactNode, useEffect} from "react"

interface Props {
  children: ReactNode
}

export const FormStateHandler = ({children}: Props) => {
  const threatLevelAssessmentFormDefinition = useRecoilValue(threatLevelAssessmentFormDefinitionState)
  const setThreatLevelAssessmentForm = useSetRecoilState(threatLevelAssessmentFormState)

  useEffect(
    () =>
      setThreatLevelAssessmentForm(prev => {
        const form: Form = {
          definition: threatLevelAssessmentFormDefinition,
          fields: {}
        }
        Object
          .entries(threatLevelAssessmentFormDefinition)
          .forEach(([symbol, definition]) => {
            form.fields[symbol] = (definition.preserve && prev.fields[symbol])
              ? prev.fields[symbol]
              : {
                value: '',
                error: false
              }
          })

        return form
      }),
    [threatLevelAssessmentFormDefinition, setThreatLevelAssessmentForm]
  )

  return (
    <>
      {children}
    </>
  )
}
