import {createStyles, createTheme, Theme} from "@material-ui/core"
import Container from "@material-ui/core/Container"
import LinearProgress from "@material-ui/core/LinearProgress"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React, {Suspense} from 'react'
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom"
import {Authenticator} from "./components/Authenticator/Authenticator"
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary"
import {RiskAssessmentForm} from "./components/RiskAssessmentForm/RiskAssessmentForm"
import {RiskAssessmentResult} from "./components/RiskAssessmentResult/RiskAssessmentResult"
import {TopBar} from "./components/TopBar/TopBar"
import {FormStateHandler} from "./components/FormStateHandler/FormStateHandler"
import CssBaseline from "@material-ui/core/CssBaseline"
import {ThemeProvider} from "@material-ui/core/styles"
import {RecoilRoot} from "recoil"
import {RecoilAtomStateChangeLogger} from "./components/RecoilAtomStateChangeLogger/RecoilAtomStateChangeLogger"
import {Authenticator as AmplifyAuthenticator} from "@aws-amplify/ui-react"

const theme = createTheme({
  palette: {
    primary: {
      main: '#36393c'
    },
    secondary: {
      main: '#970c21'
    }
  }
})

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    '@global': {
      '.MuiButton-root': {
        marginTop: '1em',
        marginBottom: '1em'
      }
    },
    container: {
      marginTop: _theme.spacing(1),
      marginBottom: _theme.spacing(1)
    }
  })
)

export const App = () => {
  const classes = useStyles()

  return (
    <BrowserRouter>
      <RecoilRoot>
        <RecoilAtomStateChangeLogger/>
        <CssBaseline/>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<LinearProgress color="secondary"/>}>
            <ErrorBoundary>
              <AmplifyAuthenticator.Provider>
                <TopBar/>
                <Container maxWidth="sm" className={classes.container}>
                  <Authenticator>
                    <ErrorBoundary>
                      <Suspense fallback={<LinearProgress color="secondary"/>}>
                        <FormStateHandler>
                          <Switch>
                            <Redirect exact from="/" to="/threat-level-assessment"/>
                            <Redirect exact from="/threat-level-assessment" to="/threat-level-assessment/query"/>
                            <Route exact path="/threat-level-assessment/query" component={RiskAssessmentForm}/>
                            <Route exact path="/threat-level-assessment/result" component={RiskAssessmentResult}/>
                            <Route>
                              <Redirect to="/"/>
                            </Route>
                          </Switch>
                        </FormStateHandler>
                      </Suspense>
                    </ErrorBoundary>
                  </Authenticator>
                </Container>
              </AmplifyAuthenticator.Provider>
            </ErrorBoundary>
          </Suspense>
        </ThemeProvider>
      </RecoilRoot>
    </BrowserRouter>
  )
}
