import {createStyles, IconButton, makeStyles, Theme} from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Popover from "@material-ui/core/Popover"
import Typography from "@material-ui/core/Typography"
import {Info} from "@material-ui/icons"
import React, {ReactNode} from "react"
import {Trans} from "react-i18next"
import {useTranslationKey} from "../../hooks/useTranslationKey"

interface Props {
  children: ReactNode
  hintKeys: string | string[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      '& > *': {
        width: "100%"
      }
    },
    typography: {
      padding: theme.spacing(2),
    },
  }),
)

export const InfoDecorator = ({children, hintKeys}: Props) => {
  const hintKey = useTranslationKey(hintKeys)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const iconVisibility = hintKey ? "visible" : "hidden"
  const classes = useStyles()
  return (
    <Box display="flex" alignItems="center" flexDirection="row">
      <Box flexGrow={1} className={classes.main}>
        {children}
      </Box>
      <IconButton style={{visibility: iconVisibility}} onClick={handleClick} tabIndex={-1}>
        <Info/>
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}><Trans>{hintKey}</Trans></Typography>
      </Popover>
    </Box>
  )
}
