import {createStyles, Theme} from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import deepOrange from "@material-ui/core/colors/deepOrange"
import IconButton from "@material-ui/core/IconButton"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React, {useRef, useState} from "react"
import {UserMenu} from "./UserMenu/UserMenu"
import {useAuthenticator} from "@aws-amplify/ui-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    }
  })
)

export const UserAvatar = () => {
  const {user} = useAuthenticator(context => [context.user])
  const classes = useStyles()

  const anchor = useRef(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const handleClick = () => {
    setMenuOpen(true)
  }
  const handleClose = () => {
    setMenuOpen(false)
  }

  if (!user?.attributes || !user?.username) {
    return null
  }

  return <>
    <IconButton onClick={handleClick} ref={anchor}>
      <Avatar className={classes.avatar}>{user.username[0].toUpperCase()}</Avatar>
    </IconButton>
    <UserMenu open={menuOpen} anchor={anchor.current} handleClose={handleClose}/>
  </>
}
