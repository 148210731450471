import {Divider, Popover} from "@material-ui/core"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import {Auth} from "aws-amplify"
import React from "react"
import {useTranslation} from "react-i18next"
import {useAuthenticator} from "@aws-amplify/ui-react";

interface UserMenuProps {
  open: boolean
  anchor: any
  handleClose: () => void
}

export const UserMenu = ({open, anchor, handleClose}: UserMenuProps) => {
  const {t} = useTranslation()
  const {user} = useAuthenticator(context => [context.user])

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <List>
        <ListItem disabled>
          <ListItemText>{user.attributes?.name}</ListItemText>
        </ListItem>
        <Divider/>
        <ListItem button component="a" onClick={() => Auth.signOut()}>
          <ListItemText>{t('Wyloguj')}</ListItemText>
        </ListItem>
      </List>
    </Popover>
  )
}
