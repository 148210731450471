import {selector} from "recoil"
import {config} from "../common/config"

interface RiskFactorDto {
  symbol: string
  opcje?: string[]
}

interface InstrumentDto {
  symbol: string
  typ: string
  czynnikiRyzyka: RiskFactorDto[]
}

const toInstrument = (dto: InstrumentDto): Instrument => (
  {
    symbol: dto.symbol,
    type: dto.typ,
    riskFactors: dto.czynnikiRyzyka.map(f => ({
      symbol: f.symbol,
      options: f.opcje
    }))
  }
)

interface RiskFactor {
  symbol: string,
  options?: string[]
}

export interface Instrument {
  symbol: string
  type: string
  riskFactors: RiskFactor[]
}

export const instrumentsState = selector({
  key: 'instrumentsState',
  get: () =>
    config
      .get('/konfiguracja/aparaty.json')
      .then(res => res.data as InstrumentDto[])
      .then(dtos => dtos.map(toInstrument))
})
