import {selector} from "recoil"
import {api} from "../common/api"
import {threatLevelAssessmentRequestState} from "./threatLevelAssessmentRequest"
import {threatLevelAssessmentFormValidState} from "./threatLevelAssessmentFormValid"

interface ThreatLevelAssessment {
  threatLevel: string,
  threatLevelMitigated: string,
  aggregatedRisk: string,
  riskMitigationFactors: string[],
}

export const threatLevelAssessmentState = selector({
  key: 'threatLevelAssessmentState',
  get: async ({get}): Promise<ThreatLevelAssessment | undefined> => {
    const request = get(threatLevelAssessmentRequestState)
    const formValid = get(threatLevelAssessmentFormValidState)
    if (!formValid) {
      return Promise.resolve(undefined)
    }

    console.log("Sending request", request)
    return (await api())
      .post('/api/v1/threat-level-assessments', request)
      .then(res => res.data as ThreatLevelAssessment)
  }
})
