import {AlertTitle} from "@material-ui/lab";
import React from "react";
import {StyledAlert} from "../../Styled/Styled";

export const AccountConfirmationRequired = () => {
  return <StyledAlert severity="info">
    <AlertTitle><strong>Jesteśmy o krok od aktywacji Twojego konta!</strong></AlertTitle>
    <p>Dziękujemy za zarejestrowanie konta na naszej stronie internetowej!</p>
    <p>Pragniemy Cię poinformować, że teraz Twoje konto przejdzie proces weryfikacji przez jednego z naszych
      pracowników. Ten etap jest niezwykle ważny, aby zapewnić bezpieczeństwo naszych usług.
      Zależy nam na Twojej wygodzie, dlatego prosimy o cierpliwość - proces ten może zająć chwilę. Jak tylko zakończy
      się weryfikacja, poinformujemy Cię o tym mailowo. </p>
    <p>Do czasu pozytywnej weryfikacji, nie będziesz mógł korzystać z naszej aplikacji.
      Jesteśmy pewni, że ten czas szybko minie i możemy już niedługo powitać Cię jako pełnoprawnego użytkownika naszego
      serwisu!</p>
    <p>Jeszcze raz dziękujemy za wybór naszej platformy i cierpliwość.</p>
    <p>Pozdrawiamy serdecznie,<br/>
      Zespół <strong><a href="https://grupa-wolff.eu/">GRUPA WOLFF</a></strong></p>
  </StyledAlert>
}
